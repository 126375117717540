import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

import axios from "axios"
import { pdf } from "@react-pdf/renderer"

import Seo from "../components/seo"
import ProductList from "../components/products/ProductList"
import Layout from "../components/layout.js"
import QuotePDF from "../components/quotePDF";

const API_URL = process.env.BASE_API_URL
const token = process.env.STRAPI_ACCESS_TOKEN

const Quote = ({ data, location }) => {
  const { t } = useTranslation()

  const categories = data.allStrapiCategory.nodes
  const products = data.allStrapiProduct.nodes

  const [selected, setSelected] = useState(t("beltTypePlaceholder"))
  const [error, setError] = useState(null)

  const pdfTranslation = {
    quotePdfTitle: t("quotePdfTitle"),
    quotePdfDescription: t("quotePdfDescription"),
    customerTableTitle: t("customerTableTitle"),
    aRow: `${t("aLabel")}:`,
    bRow: `${t("bLabel")}:`,
    cRow: `${t("cLabel")}:`,
    dRow: `${t("dLabel")}:`,
    hRow: `${t("hLabel")}:`,
    oRow: `${t("oLabel")}:`,
    pRow: `${t("pLabel")}:`,
    sRow: `${t("sLabel")}:`,
    widthRow: t("widthRow"),
    heightRow: t("heightRow"),
    materialRow: t("materialRow"),
    companyNameRow: t("companyNameRow"),
    authorityNameRow: t("authorityNameRow"),
    phoneRow: t("phoneRow"),
    emailRow: t("emailRow"),
    messageRow: t("messageRow"),
    chainDimensionsRow: `${t("chainDimensionsLabel")}:`,
    chainMaterialRow: `${t("chainMaterialLabel")}:`
  }

  const getQuoteDateTime = () => {
    let today = new Date();
    let date = today.toLocaleDateString("tr-TR").split(".").reverse().join("");
    let time = today.toLocaleTimeString("tr-TR").split(":")[0] + today.toLocaleTimeString("tr-TR").split(":")[1];

    return date + "_" + time;
  }

  useEffect(() => {
    if (location.state) {
      setSelected(products.find(product => product.strapi_id === location.state.strapi_id))
    }
  }, [])

  const defaultValues = {
    companyName: "",
    authorityName: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    width: "",
    height: "",
    material: "",
    authorityPosition: "",
    message: "",
    A: "",
    B: "",
    C: "",
    D: "",
    H: "",
    O: "",
    P: "",
    S: "",
    product: "",
  }

  const [productValues, setProductValues] = useState(defaultValues)
  const [chainBeltValues, setChainBeltValues] = useState({ chainDimensions: "", chainMaterial: "" })
  const [serverResponse, setServerResponse] = useState({ submitting: false, id: 0 })

  const handleChange = e => {
    const { name, value } = e.target
    setProductValues(prevState => ({ ...prevState, [name]: value }))
  }

  const getQuoteForm = async (productId) => {
    return await axios.get(
      API_URL + "/quotes/" + productId + "?populate[product][populate]=%2A&populate[pdf]=*",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
  }

  const createQuoteForm = async (values) => {
    return await axios
      .post(
        API_URL + "/quotes",
        { data: values },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
  }

  const uploadPDF = async (formData) => {
    return await axios
      .post(
        API_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      )
  }

  const sendEmail = async (values) => {
    return await axios
      .post(
        API_URL + "/tbant/email",
        { data: values },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
  }

  const sendQuoteForm = (data) => {
    const formData = new FormData();
    let productCode = data?.attributes.product.data.attributes.title.toLowerCase();
    let currentDate = getQuoteDateTime();
    let fileName = `${productCode}_${currentDate}.pdf`
    let pdfBlob = pdf(<QuotePDF quoteData={data} translation={pdfTranslation} chainBeltData={chainBeltValues} />).toBlob();
    pdfBlob.then(res => {
      formData.append("files", res, fileName, { type: res.type })
      formData.append("field", "pdf")
      formData.append("ref", "api::quote.quote")
      formData.append("refId", data?.id)
      uploadPDF(formData).then(res => {
        getQuoteForm(data.id).then(res => {
          let pdfCTA = res.data.data.attributes.pdf.data
          const statusEmail = {
            tbant: {
              "to": "info@tbantsan.com",
              "subject": `${t("tbantEmailSubject")} ${data.attributes.email}`,
              "preheader": t("tbantEmailPreheader"),
              "text": `${t("tbantEmailText")}`,
              "cta": `${t("tbantEmailCTA")}`,
              "ctaLink": pdfCTA?.attributes.url,
            },
            user: {
              "to": `${data.attributes.email}`,
              "subject": t("userEmailSubject"),
              "preheader": t("userEmailPreheader"),
              "text": `${t("userEmailText")} ${pdfCTA?.attributes.url}`,
              "cta": `${t("userEmailCTA")}`,
              "ctaLink": pdfCTA?.attributes.url,
            }
          }
          sendEmail(statusEmail.tbant).then(res => {
            sendEmail(statusEmail.user).then(res => {
              setServerResponse({ submitting: true, id: data.id })
            }).catch(err => setError(err))
          }).catch(err => setError(err))
        }).catch(err => setError(err))
      }).catch(err => setError(err))
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    let values = {
      ...productValues,
      authorityName: productValues.firstName + " " + productValues.lastName,
      product: selected.strapi_id
    }

    createQuoteForm(values)
      .then(res => {
        let quoteId = res.data.data.id
        getQuoteForm(quoteId).then(res => {
          let quoteData = res.data.data
          sendQuoteForm(quoteData)
        }).catch(err => setError(err))
      }).catch(res => console.log(res))
  }

  const calcRange = data => {
    if (data.includes("*")) {
      return data.split("*")[0] * data.split("*")[1]
    } else if (data.includes("/")) {
      return data.split("/")[0] / data.split("/")[1]
    } else {
      return data
    }
  }

  const range = data => {
    // let pattern = new RegExp("/(m|\')/g")
    const values = data
      .replaceAll("m", "")
      .replaceAll("'", "")
      .replaceAll(",", ".")
      .split("-")

    return { min: calcRange(values[0]), max: calcRange(values[1]) }
  }

  return (
    <Layout className="bg-lightGray" navbarTheme="dark">
      <Seo title={t("metaQuoteTitle")} description={t("metaQuoteDescription")} keywords={t("metaQuoteKeywords")} />
      <div className="bg-lightGray relative">
        {!serverResponse.submitting ?
          <div className="max-w-7xl mx-auto px-6 md:px-10 py-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-darkBlue">
              {t("chooseProductsTitle")}
            </h1>
            {error === null && <p className="text-darkBlue text-lg max-w-xs md:max-w-xl mt-16">{t("quoteDescription")}</p>}
            {error !== null && <p className="text-red text-lg max-w-xs md:max-w-xl mt-16">{t("quoteFormError")}</p>}
            <form onSubmit={handleSubmit}>
              <div className="w-full my-16 flex flex-col">
                <label className="text-sm text-blue">{t("beltTypeLabel")}</label>
                <select
                  value={selected?.strapi_id || selected}
                  className="select-icon appearance-none bg-no-repeat w-full outline-none bg-transparent placeholder-darkBlue border-b border-darkBlue py-2 text-darkBlue"
                  onChange={e => {
                    const selectedProduct = products.find(product => product.strapi_id == e.target.value)
                    setSelected(selectedProduct)
                    setProductValues(defaultValues)
                  }}
                >
                  <option value={t("beltTypePlaceholder")} disabled>
                    {t("beltTypePlaceholder")}
                  </option>
                  {categories.map(category => (
                    <optgroup key={category.id} label={category.title}>
                      {products.filter(product => product.category.id === category.id)
                        .map(product => (
                          <option key={product.strapi_id} value={product.strapi_id}>
                            {product.title}
                          </option>
                        ))}
                    </optgroup>
                  ))}
                </select>
              </div>

              {selected?.strapi_id && (
                <div className="md:flex">
                  <div className="md:w-1/2 py-6 md:p-10">
                    <div className="overflow-hidden">
                      <GatsbyImage
                        image={getImage(selected.size_drawing?.localFile)}
                        alt={selected.size_drawing?.alternativeText}
                        className="transition hover:scale-125"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/2 py-5">
                    <div className="w-full md:flex flex-wrap">
                      {selected.A && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="A">
                            {t("aLabel")}{" "}<span className={`${productValues.A.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.A.replaceAll(",", ".")}</span>
                          </label>
                          {selected.A.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="A"
                              placeholder={selected.A.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.A}
                              min={range(selected.A).min}
                              max={range(selected.A).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="A"
                              placeholder={selected.A.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.A}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.B && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="B">
                            {t("bLabel")}{" "}<span className={`${productValues.B.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.B.replaceAll(",", ".")}</span>
                          </label>
                          {selected.B.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="B"
                              placeholder={selected.B.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.B}
                              min={range(selected.B).min}
                              max={range(selected.B).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="B"
                              placeholder={selected.B.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.B}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.C && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="C">
                            {t("cLabel")}{" "}<span className={`${productValues.C.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.C.replaceAll(",", ".")}</span>
                          </label>
                          {selected.C.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="C"
                              placeholder={selected.C.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.C}
                              min={range(selected.C).min}
                              max={range(selected.C).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="C"
                              placeholder={selected.C.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.C}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.D && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="D">
                            {t("dLabel")}{" "}<span className={`${productValues.D.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.D.replaceAll(",", ".")}</span>
                          </label>
                          {selected.D.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="D"
                              placeholder={selected.D.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.D}
                              min={range(selected.D).min}
                              max={range(selected.D).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="D"
                              placeholder={selected.D.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.D}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.H && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="H">
                            {t("hLabel")}{" "}<span className={`${productValues.H.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.H.replaceAll(",", ".")}</span>
                          </label>
                          {selected.H.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="H"
                              placeholder={selected.H.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.H}
                              min={range(selected.H).min}
                              max={range(selected.H).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="H"
                              placeholder={selected.H.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.H}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.O && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="O">
                            {t("oLabel")}{" "}<span className={`${productValues.O.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.O.replaceAll(",", ".")}</span>
                          </label>
                          {selected.O.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="O"
                              placeholder={selected.O.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.O}
                              min={range(selected.O).min}
                              max={range(selected.O).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="O"
                              placeholder={selected.O.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.O}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}

                      {selected.P && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="P">
                            {t("pLabel")}{" "}<span className={`${productValues.P.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.P.replaceAll(",", ".")}</span>
                          </label>
                          {selected.P.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="P"
                              placeholder={selected.P.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.P}
                              min={range(selected.P).min}
                              max={range(selected.P).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="P"
                              placeholder={selected.P.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.P}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                      {selected.S && (
                        <div className="w-full md:w-1/4 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue" htmlFor="S">
                            {t("sLabel")}{" "}<span className={`${productValues.S.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all`}>{selected.S.replaceAll(",", ".")}</span>
                          </label>
                          {selected.S.includes("-") ?
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="S"
                              placeholder={selected.S.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.S}
                              min={range(selected.S).min}
                              max={range(selected.S).max}
                              step="any"
                            /> :
                            <input
                              className="py-2 outline-none bg-transparent border-b border-darkBlue md:mr-5 text-darkBlue"
                              required
                              type="number"
                              name="S"
                              placeholder={selected.S.replaceAll(",", ".")}
                              onChange={handleChange}
                              value={productValues.S}
                              min="0"
                              step="any"
                            />
                          }
                        </div>
                      )}
                    </div>

                    <div className="w-full md:flex md:space-x-5">
                      <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                        <label className="text-sm text-blue">
                          {t("heightLabel")}
                        </label>
                        <input
                          className="py-2 outline-none bg-transparent border-b border-darkBlue text-darkBlue"
                          required
                          type="number"
                          name="height"
                          placeholder={selected.height}
                          onChange={handleChange}
                          value={productValues.height}
                        />
                      </div>
                      <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                        <label className="text-sm text-blue">
                          {t("widthLabel")}
                        </label>
                        <input
                          className="py-2 outline-none bg-transparent border-b border-darkBlue text-darkBlue"
                          required
                          type="number"
                          name="width"
                          placeholder={selected.width}
                          onChange={handleChange}
                          value={productValues.width}
                        />
                      </div>
                    </div>
                    <div className="w-full my-10 flex flex-col">
                      <label className="text-sm text-blue">
                        {t("materialLabel")}
                      </label>
                      <select
                        className="select-icon appearance-none bg-no-repeat w-full outline-none bg-transparent py-2 placeholder-darkBlue border-b border-darkBlue text-darkBlue"
                        name="material"
                        id="material"
                        onChange={handleChange}
                        value={productValues.material}
                      >
                        <option value="">{t("materialPlaceholder")}</option>
                        {selected.materials.map(material => (
                          <option key={material.id} value={material.title}>
                            {material.title}
                          </option>
                        ))}
                      </select>
                    </div>

                    {(selected.category.slug === "chain-driven-mesh-conveyor-belt" || selected.category.slug === "zincirli-tel-bant") &&
                      <div className="w-full md:flex md:space-x-5">
                        <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue">
                            {t("chainDimensionsLabel")}
                          </label>
                          <select
                            className="select-icon appearance-none bg-no-repeat outline-none bg-transparent py-2 placeholder-darkBlue border-b border-darkBlue text-darkBlue"
                            name="chainDimensions"
                            id="chainDimensions"
                            required
                            onChange={(e) => setChainBeltValues(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}
                          >
                            <option value="">{t("chainDimensionsLabel")}</option>
                            <option value="06B1 – 3/8’’ ( 9.585mm )">06B1 – 3/8’’ ( 9.585mm )</option>
                            <option value="08B1 – ½ ‘’ ( 12,7mm)">08B1 – ½ ‘’ ( 12,7mm)</option>
                            <option value="10B1 – 5/8’’ ( 15.875mm)">10B1 – 5/8’’ ( 15.875mm)</option>
                            <option value="12B1 – ¾’’ ( 19,05mm )">12B1 – ¾’’ ( 19,05mm )</option>
                            <option value="16B – 1’’ ( 25,4mm )">16B – 1’’ ( 25,4mm )</option>
                            <option value="20B – 1-1/4’’ ( 31,75mm)">20B – 1-1/4’’ ( 31,75mm)</option>
                            <option value="32B – 2’’ ( 50,8mm )">32B – 2’’ ( 50,8mm )</option>
                          </select>
                        </div>
                        <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                          <label className="text-sm text-blue">
                            {t("chainMaterialLabel")}
                          </label>
                          <select
                            className="select-icon appearance-none bg-no-repeat outline-none bg-transparent py-2 placeholder-darkBlue border-b border-darkBlue text-darkBlue"
                            name="chainMaterial"
                            id="chainMaterial"
                            required
                            onChange={(e) => setChainBeltValues(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}
                          >
                            <option value="">{t("chainMaterialLabel")}</option>
                            <option value={t("carbonSteel")}>{t("carbonSteel")}</option>
                            <option value="AISI-304">AISI-304</option>
                          </select>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )}

              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold py-10 text-darkBlue">
                {t("companyInfoTitle")}
              </h2>

              <input type="hidden" name="form-name" value="contact" />
              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.firstName.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("nameLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="text"
                    name="firstName"
                    placeholder={t("namePlaceholder")}
                    onChange={handleChange}
                    value={productValues.firstName}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.lastName.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("surnameLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="text"
                    name="lastName"
                    placeholder={t("surnamePlaceholder")}
                    onChange={handleChange}
                    value={productValues.lastName}
                  />
                </div>
              </div>

              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.phone.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("phoneLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="text"
                    name="phone"
                    placeholder={t("phonePlaceholder")}
                    onChange={handleChange}
                    value={productValues.phone}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.email.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("emailLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="email"
                    name="email"
                    placeholder={t("emailPlaceholder")}
                    onChange={handleChange}
                    value={productValues.email}
                  />
                </div>
              </div>

              <div className="w-full md:flex md:space-x-5">
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.companyName.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("companyLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="text"
                    name="companyName"
                    placeholder={t("companyPlaceholder")}
                    onChange={handleChange}
                    value={productValues.companyName}
                  />
                </div>
                <div className="md:w-1/2 my-5 md:my-5 flex flex-col">
                  <label className={`${productValues.authorityPosition.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("positionLabel")}</label>
                  <input
                    className="py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                    required
                    type="text"
                    name="authorityPosition"
                    placeholder={t("positionPlaceholder")}
                    onChange={handleChange}
                    value={productValues.authorityPosition}
                  />
                </div>
              </div>

              <div className="w-full my-5 md:my-5 flex flex-col">
                <label className={`${productValues.message.length > 0 ? 'opacity-1' : 'opacity-0'} transition-all text-sm text-blue`}>{t("messageLabel")}</label>
                <textarea
                  className="resize-marker-hidden py-2 outline-none bg-transparent border-b border-darkBlue placeholder-darkBlue text-darkBlue"
                  type="text"
                  name="message"
                  placeholder={t("messagePlaceholder")}
                  onChange={handleChange}
                  value={productValues.message}
                  maxLength={255}
                  rows="1"
                />
              </div>

              <div className="flex">
                <button
                  className="inline-block transition bg-purple px-5 mx-auto mt-16 py-2 text-white font-light border border-transparent hover:border-white hover:bg-darkBlue disabled:text-blue disabled:border-blue disabled:bg-logoGray disabled:cursor-auto"
                  type="submit"
                  disabled={!selected?.strapi_id}
                >
                  {t("quoteCTA")}
                </button>
              </div>
            </form>

            <div className="py-10">
              <p className="w-full text-4xl md:text-5xl lg:text-6xl font-bold py-8 md:py-16 text-darkBlue">
                {t("ourProducts")}
              </p>

              <div className="flex flex-wrap -mx-2 md:-mx-5">
                <ProductList categories={categories} />
              </div>
            </div>
          </div> :
          <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl mx-auto mb-32">
            <div className="text-center md:py-10 lg:py-20 p-10 flex flex-col justify-center items-center relative z-20">
              <h1 className="text-darkBlue font-bold text-5xl lg:text-6xl">{t("quoteSuccessTitle")}</h1>
              <p className="text-darkBlue text-lg font-light my-16 whitespace-pre-line">
                {t("quoteSuccessDescription")}
              </p>
              <Link to="/" className="w-max block p-2 text-sm text-white font-light bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
                {t("backToHomeCTA")}
              </Link>
            </div>
          </div>
        }
      </div>
    </Layout>
  )
}

export default Quote

export const query = graphql`
  query ($language: String!) {
    allStrapiCategory(filter: { locale: { eq: $language } }) {
      nodes {
        id
        slug
        title
        locale
        featuredImg {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.3)
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiProduct(filter: { locale: { eq: $language } }) {
      nodes {
        id
        strapi_id
        title
        category {
          id
          slug
          title
        }
        size_drawing {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        A
        B
        C
        D
        H
        O
        P
        S
        materials {
          id
          title
        }
      }
    }
  }
`