import React from "react"

import {
  Page,
  View,
  Image,
  Text,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer"

const LOCAL_URL = "http://localhost:8000"

const QuotePDF = ({ quoteData, translation, chainBeltData }) => {

  Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  })

  Font.registerHyphenationCallback(word => [word])

  const styles = StyleSheet.create({
    table: { display: "flex", width: "100%", flexWrap: "wrap", borderCollapse: "collapse" },
    tableRow: { display: "flex", flexDirection: "row" },
    tableData: { fontSize: 10, display: "flex", flexGrow: 1, alignItems: "center", border: "1px solid black", marginBottom: "-1px", marginLeft: "-1px", boxSizing: "border-box", minHeight: "30px", padding: "7px 5px" },
  })

  const customerTable = (
    <View style={[styles.table, { margin: "20 0" }]}>
      <Text style={{ textAlign: "center", marginBottom: 10 }}>{translation.customerTableTitle}</Text>
      <View style={[styles.tableRow, { margin: "auto" }]}>
        <Text style={[styles.tableData, { width: "100px" }]}>{translation.companyNameRow}</Text>
        <Text style={[styles.tableData, { width: "200px" }]}>{quoteData?.attributes.companyName}</Text>
      </View>
      <View style={[styles.tableRow, { margin: "auto" }]}>
        <Text style={[styles.tableData, { width: "100px" }]}>{translation.authorityNameRow}</Text>
        <Text style={[styles.tableData, { width: "200px" }]}>{quoteData?.attributes.authorityName}</Text>
      </View>
      <View style={[styles.tableRow, { margin: "auto" }]}>
        <Text style={[styles.tableData, { width: "100px" }]}>{translation.phoneRow}</Text>
        <Text style={[styles.tableData, { width: "200px" }]}>{quoteData?.attributes.phone}</Text>
      </View>
      <View style={[styles.tableRow, { margin: "auto" }]}>
        <Text style={[styles.tableData, { width: "100px" }]}>{translation.emailRow}</Text>
        <Text style={[styles.tableData, { width: "200px" }]}>{quoteData?.attributes.email}</Text>
      </View>
    </View>
  )

  const productTable = (
    <View style={styles.table}>
      {quoteData?.attributes.A &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.aRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.A}</Text>
        </View>}
      {quoteData?.attributes.B &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.bRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.B}</Text>
        </View>}
      {quoteData?.attributes.C &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.cRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.C}</Text>
        </View>}
      {quoteData?.attributes.D &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.dRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.D}</Text>
        </View>}
      {quoteData?.attributes.H &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.hRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.H}</Text>
        </View>}
      {quoteData?.attributes.O &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.oRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.O}</Text>
        </View>}
      {quoteData?.attributes.P &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.pRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.P}</Text>
        </View>}
      {quoteData?.attributes.S &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.sRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.S}</Text>
        </View>}
      <View style={styles.tableRow}>
        <Text style={[styles.tableData, { width: "50px" }]}>{translation.heightRow}</Text>
        <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.height}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={[styles.tableData, { width: "50px" }]}>{translation.widthRow}</Text>
        <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.width}</Text>
      </View>
      {quoteData?.attributes.material &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.materialRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{quoteData?.attributes.material}</Text>
        </View>}
      {chainBeltData.chainDimensions &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.chainDimensionsRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{chainBeltData.chainDimensions}</Text>
        </View>}
      {chainBeltData.chainMaterial &&
        <View style={styles.tableRow}>
          <Text style={[styles.tableData, { width: "50px" }]}>{translation.chainMaterialRow}</Text>
          <Text style={[styles.tableData, { width: "100px", textAlign: "right" }]}>{chainBeltData.chainMaterial}</Text>
        </View>}
    </View>
  )


  return (
    <Document>
      {quoteData &&
        <Page
          size="A4"
          style={{
            padding: "20 40",
            fontFamily: "Roboto",
          }}
        >
          <View style={{ padding: "40 0", position: "relative" }}>
            <View style={{ position: "absolute", top: "0", left: "0" }}>
              <Image
                style={{ width: "80px" }}
                src={`${LOCAL_URL}/images/logo-dark.png`}
              />
            </View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {quoteData.attributes.product.data.attributes.title} {translation.quotePdfTitle}
            </Text>
          </View>
          <Text style={{ fontWeight: "hairline", fontSize: 12 }}>
            {translation.quotePdfDescription}
          </Text>
          <View>
            {customerTable}
          </View>
          <View style={{ display: "flex", flexDirection: "row", margin: "20 0" }}>
            <View style={{ width: "60%" }}>
              <Image
                style={{ width: "100%" }}
                src={quoteData.attributes.product.data.attributes.size_drawing.data.attributes.url}
              />
            </View>
            <View style={{ width: "40%" }}>
              {productTable}
            </View>
          </View>

          <View style={{ marginTop: 100 }}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableData, { width: "30%" }]}>{translation.messageRow}</Text>
                <Text style={[styles.tableData, { width: "70%" }]}>{quoteData.attributes.message}</Text>
              </View>
            </View>
          </View>
        </Page>}
    </Document>
  )
}

export default QuotePDF
