import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Link } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";

const ProductList = ({ categories }) => {

  return categories.map(category => {
    const image = getImage(category.featuredImg?.localFile);
    const url = category.locale === 'tr' ? 'tel-bant' : 'conveyor-belts'

    return (
      <Link key={category.id} to={"/" + url + "/" + category.slug} className="block w-1/2 px-2 md:px-5 my-5">
        <div className="overflow-hidden">
          <GatsbyImage
            image={image}
            alt={category.featuredImg?.alternativeText}
            objectFit="contain"
            className="transition hover:scale-110"
          />
        </div>

        <div>
          <p className="py-4 md:text-lg lg:text-xl font-bold">{category.title}</p>
        </div>
      </Link>
    )
  })
}

export default ProductList

export const query = graphql`
query($language: String){
  locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`